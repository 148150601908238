<template>
    <div>
        <div class="form-table">
            <div style="text-align: center">
                <span style="font-size: 26px">
                    {{ workFlowState || '任务下发通知单' }}
                </span>
            </div>
            <div>
                <el-form
                    ref="dataForm"
                    :model="dataForm"
                    :rules="dataRule"
                    disabled
                    label-width="120px"
                    label-position="top"
                >
                    <ex-card headerName="任务通知单" style="margin-top: 30px">
                        <div>
                            <el-row :gutter="24">
                                <el-col :span="6">
                                    <el-form-item label="任务编号" prop="jobOrder.taskCode">
                                        <el-input v-model="dataForm.jobOrder.taskCode" placeholder="请填写任务编号">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="通知单位" prop="jobOrder.jobUnit">
                                        <el-select
                                            v-model="jobUnitId"
                                            @change="selectUnit"
                                            clearable
                                            placeholder="请选择通知单位"
                                        >
                                            <el-option
                                                v-for="item in unitList"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="被通知人" prop="jobOrder.jobStaff">
                                        <el-select v-model="dataForm.jobOrder.jobStaff" placeholder="请选择被通知人">
                                            <el-option
                                                v-for="item in maintenanceOwnerList"
                                                :key="item.userId"
                                                :label="item.realName"
                                                :value="item.realName"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item
                                        label="工作类型"
                                        prop="jobOrder.workType"
                                        placeholder="请选择工作类型"
                                    >
                                        <el-select v-model="dataForm.jobOrder.workType">
                                            <el-option
                                                v-for="item in workTypeList"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                        <!--<el-input :disabled="this.start" v-model="dataForm.jobOrder.workType" placeholder="请输入监理姓名"></el-input>-->
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="监理" prop="">
                                        <el-input v-model="dataForm.jobOrder.supervisor"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="完成期限" prop="jobOrder.endTime">
                                        <el-date-picker
                                            placeholder="请选择完成期限"
                                            v-model="dataForm.jobOrder.endTime"
                                            style="width: 100%"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="电压等级" prop="jobOrder.voltageClasses">
                                        <el-select
                                            v-model="dataForm.jobOrder.voltageClasses"
                                            placeholder="请选择电压等级"
                                        >
                                            <el-option
                                                v-for="item in voltageClassesList"
                                                :value="item.id"
                                                :label="item.name"
                                                :key="item.name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-form-item label="工作任务" prop="jobOrder.jobContent">
                                    <el-input
                                        v-model="dataForm.jobOrder.jobContent"
                                        type="textarea"
                                        rows="3"
                                        placeholder="输入内容"
                                    ></el-input>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="备注">
                                    <el-input
                                        v-model="dataForm.jobOrder.remark"
                                        type="textarea"
                                        rows="3"
                                        placeholder="输入内容"
                                    ></el-input>
                                </el-form-item>
                            </el-row>
                        </div>
                    </ex-card>
                    <ex-card
                        headerName="缺陷回执单"
                        style="margin-top: 30px"
                        v-if="workFlowCode != 'elec_task_issue_start'"
                    >
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="处理结果" prop="receipt.taskResult">
                                    <el-select v-model="dataForm.receipt.taskResult" placeholder="处理结果">
                                        <el-option
                                            v-for="item in taskResultList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.name"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="处理措施分类" prop="receipt.measure">
                                    <el-select v-model="dataForm.receipt.measure" placeholder="请选择处理措施分类">
                                        <el-option
                                            v-for="item in treatmentMeasureClassifyList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.name"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="消缺开始时间" prop="receipt.taskStartTime">
                                    <el-date-picker
                                        placeholder="请选择消缺开始时间"
                                        v-model="dataForm.receipt.taskStartTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="消缺结束时间" prop="receipt.taskEndTime">
                                    <el-date-picker
                                        placeholder="请选择消缺结束时间"
                                        v-model="dataForm.receipt.taskEndTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="处理内容" prop="receipt.taskContent">
                                <el-input
                                    v-model="dataForm.receipt.taskContent"
                                    type="textarea"
                                    rows="3"
                                    placeholder="请填写处理内容"
                                ></el-input>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="备注">
                                <el-input
                                    v-model="dataForm.receipt.remark"
                                    type="textarea"
                                    rows="3"
                                    placeholder="输入内容"
                                ></el-input>
                            </el-form-item>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门">
                                    <el-input
                                        v-model="dataForm.receipt.roadworkUnit"
                                        placeholder="请填写施工单位/部门"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门责任人">
                                    <el-input
                                        v-model="dataForm.receipt.roadworkPrincipal"
                                        placeholder="请填写责任人，多个人之间以，隔开"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门验收时间">
                                    <el-date-picker
                                        placeholder="选择验收时间"
                                        v-model="dataForm.receipt.roadworkReceptionTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门意见">
                                    <el-input
                                        v-model="dataForm.receipt.roadworkIdea"
                                        placeholder="请填写验收意见"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门">
                                    <el-input
                                        v-model="dataForm.receipt.supervisorUnit"
                                        placeholder="请填写施工单位/部门"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门责任人">
                                    <el-input
                                        v-model="dataForm.receipt.supervisorPrincipal"
                                        placeholder="请填写责任人，多个人之间以，隔开"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门验收时间：">
                                    <el-date-picker
                                        placeholder="选择验收时间"
                                        v-model="dataForm.receipt.supervisorReceptionTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门意见">
                                    <el-input
                                        v-model="dataForm.receipt.supervisorIdea"
                                        placeholder="请填写验收意见"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门">
                                    <el-input
                                        v-model="dataForm.receipt.taskUnit"
                                        placeholder="请填写施工单位/部门"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门责任人">
                                    <el-input
                                        v-model="dataForm.receipt.taskPrincipal"
                                        placeholder="请填写责任人，多个人之间以，隔开"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门验收时间">
                                    <el-date-picker
                                        placeholder="选择验收时间"
                                        v-model="dataForm.receipt.taskReceptionTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门意见">
                                    <el-input
                                        v-model="dataForm.receipt.taskIdea"
                                        placeholder="请填写验收意见"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="change-line">
                            <el-row>
                                <span>消缺后图片以及总结报告上传</span>
                                <ex-uploader disabled v-model="dataForm.receipt.imageList"></ex-uploader>
                            </el-row>
                        </div>
                    </ex-card>
                    <ex-card
                        headerName="审核"
                        style="margin-top: 30px"
                        v-if="workFlowCode != 'elec_task_issue_receipt' && workFlowCode != 'elec_task_issue_start'"
                    >
                        <div style="text-align: center"></div>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="验收单位审核意见" prop="audit.auditIdea">
                                    <el-input v-model="dataForm.audit.auditIdea"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="验收单位审核时间" prop="audit.auditTime">
                                    <el-date-picker v-model="dataForm.audit.auditTime" style="width: 100%" type="date">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </ex-card>
                </el-form>
            </div>
        </div>
        <div class="submit-btn-group button-fixed">
            <el-button @click="closeForm">关闭</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            visible: true,
            reviewForm: {
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            },
            currentRow: null,
            data: {},
            showRecordHistory: false,
            rejectNodesList: [],
            dialogVisible: false,
            roleList: [],
            roleCodeList: [],
            unitList: [], //运维单位列表
            maintenanceOwnerList: [], //运维单位成员列表
            voltageClassesList: [], //电压等级列表
            taskResultList: [], //处理结果列表
            workTypeList: [], //工作类型列表
            treatmentMeasureClassifyList: [], //处理措施分类列表
            roleInfo: [], //用户信息
            userName: '', //当前登录用户账号
            nodeCode: '', // 下一步流程ID
            instanceId: '', //当前流程ID
            jumpNodeId: '', //上一步流程ID
            jobUnitId: '', //运维单位id
            definitionCode: '', //流程id
            workFlowState: null, //流程状态
            workFlowCode: '', //流程code
            start: false, //开始流程
            receipt: false, //回执流程
            audit: false, //审核流程
            dataForm: {
                jobOrder: {
                    //工作任务通知单
                    taskCode: '', // 任务编号
                    jobUnit: '', //通知单位单位
                    jobStaff: '', //被通知人
                    workType: '', //工作类型
                    supervisor: '', //监理
                    endTime: '', //完成期限
                    voltageClasses: '', //电压等级
                    jobContent: '', //工作内容
                    remark: '', //备注
                },
                receipt: {
                    //回执单
                    taskResult: '', //处理结果
                    measure: '', //处理措施分类
                    taskStartTime: '', //处理开始时间
                    taskEndTime: '', //处理结束时间
                    taskContent: '', //处理内容
                    roadworkUnit: '', //施工单位
                    roadworkPrincipal: '', //施工单位负责人
                    roadworkReceptionTime: '', //施工单位验收时间
                    roadworkIdea: '', //施工单位意见
                    supervisorUnit: '', //监理单位
                    supervisorPrincipal: '', //监理单位负责人
                    supervisorReceptionTime: '', //监理单位验收时间
                    supervisorIdea: '', //监理单位意见
                    taskUnit: '', //运维单位
                    taskPrincipal: '', //运维单位负责人
                    taskReceptionTime: '', //运维单位验收时间
                    taskIdea: '', //运维单位意见
                    remark: '', //备注
                    imageList: [], //消缺后图片
                    finalReport: [], //总结报告
                },
                audit: {
                    //审核
                    auditIdea: '', //审核意见
                    auditTime: '', //审核时间
                    auditSignature: '', //验收单位签字
                },
            },
            dataRule: {
                hint: [{required: true, message: '必填,不能为空', trigger: 'blur'}],
            },
        };
    },
    methods: {
        //获取运维单位成员
        selectUnit(val) {
            this.dataForm.jobOrder.jobStaff = '';
            for (let item of this.unitList) {
                if (item.id === val) {
                    this.dataForm.maintenanceUnit = item.name;
                }
            }
            this.$client.getUserByOrgId(val).then((res) => {
                this.maintenanceOwnerList = res.data;
            });
        },
        async getInitData() {
            //约定维保组织code以WB开头， 获取运维单位列表
            this.$client.getOrgUnitByOrgTypeAndCode({orgType: 'department', code: 'WB'}).then((res) => {
                this.unitList = res.data;
            });
            //获取电压等级列表
            this.$client
                .listDictionaryChildrenArr([
                    'voltage_classes',
                    'task_result',
                    'treatment_measure_classify',
                    'work_type',
                ])
                .then((res) => {
                    this.voltageClassesList = res.data.voltage_classes;
                    this.taskResultList = res.data.task_result;
                    this.treatmentMeasureClassifyList = res.data.treatment_measure_classify;
                    this.workTypeList = res.data.work_type;
                });
        },
        closeForm() {
            this.$emit('closeForm');
        },
        async init(item) {
            await this.getInitData();
            if (item) {
                this.definitionCode = item.definitionCode;
                this.instanceId = item.id;
                if (item.nextNode) {
                    this.nodeCode = item.nextNode.code;
                    this.workFlowState = item.nextNode ? item.nextNode.name : '任务下发通知单';
                    this.workFlowCode = item.nextNode ? item.nextNode.code : '';
                }
                let ids = [];
                ids.push(item.currentRecordId);
                this.$client.getQueryRecords({ids}).then((resp) => {
                    this.dataForm = resp.data.items[0].data;
                    let data = this.unitList.filter((e) => e.name === this.dataForm.jobOrder.jobUnit);
                    this.jobUnitId = data && data.length > 0 ? data[0].id : '';
                });
            }
            this.getRoleList();
            this.getUser();
            this.visible = true;
        },
        //获取昆自运维中心人员列表
        getRoleList() {
            this.$client
                .getUserListByRoleId({
                    current: 1,
                    size: 1000,
                    roleId: 15,
                })
                .then((data) => {
                    this.roleList = data.data.records;
                });
        },
        //获取用户信息
        async getUser() {
            const data = await this.$client.getUserInfo();
            let id = data.user.userId;
            let roleData = await this.$client.getUserById(id);
            this.userName = roleData.user.username;
            let res = roleData.user.sysRole;
            for (let i = 0; i < res.length; i++) {
                this.roleInfo.push(res[i].roleCode);
            }
        },
    },
};
</script>
<style lang="less" scoped>
.form-table {
    height: calc(100vh - 170px);
    margin-bottom: 10px;
    overflow-y: scroll;
}
.button-fixed {
    text-align: center;
    margin: 0 !important;
}
</style>
