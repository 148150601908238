<!--操作票管理-->
<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div v-if="showVisible" class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
            <div v-if="canAdd" class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
                <el-button @click="addOrUpdateHandle('add')" type="primary" style="border-radius: 4px">+ 下发任务</el-button>
            </div>
            <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
                <ex-search-table-pagination
                    style="height: 100%"
                    ref="searchTable"
                    :fetch="fetchData"
                    :columns="columns"
                    :formOptions="formOptions"
                    :searchWhenSortChange="true"
                    pageSizeKey="pageSize"
                    pageIndexKey="pageNumber"
                    listField="items"
                    totalField="totalItemCount"
                    :params="{definitionCode}"
                    :paramsMethod="paramsMethod"
                >
                    <template slot="append">
                        <el-table-column
                            align="center"
                            label="操作"
                            type="action"
                            width="240"
                            fixed="right"
                        >
                            <template slot-scope="scope">
                                <el-button @click="lookInfo(scope.row)">查看</el-button>
                                <el-button v-if="getButton(scope.row)" @click="conductInfo(scope.row)">处理</el-button>
                                <el-button v-if="getButton(scope.row)" @click="deleteInfo(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </template>
                </ex-search-table-pagination>
            </div>
        </div>
        <Form v-if="formVisible" ref="issuedTaskForm" @closeForm="closeForm"></Form>
        <Details v-if="detailsVisible" ref="issuedTaskDetails" @closeForm="closeForm"></Details>
    </div>
</template>

<script>
import Form from './issuedTaskForm';
import Details from './issuedTaskDetails';
import * as dayjs from 'dayjs';
export default {
    data() {
        return {
            canAdd: false, //是否有权限新增
            showVisible: true,
            formVisible: false,
            detailsVisible: false,
            items: [],
            definitionCode: 'elec_task_issue',
            taskResultList: [],
            extraProp: {},
        };
    },
    components: {
        Form,
        Details,
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'jobUnit',
                    label: '被通知单位',
                    formatter: (row, column, cellValue) => {
                        let data = null;
                        if (row && row.currentRecord) {
                            data = row.currentRecord.data.jobOrder.jobUnit;
                        }
                        return data ? data : '';
                    },
                },
                {
                    prop: 'jobStaff',
                    label: '被通知人',
                    formatter: (row, column, cellValue) => {
                        let data = null;
                        if (row && row.currentRecord) {
                            data = row.currentRecord.data.jobOrder.jobStaff;
                        }
                        return data ? data : '';
                    },
                },
                {
                    prop: 'state',
                    label: '业务状态',
                    formatter: (row, column, cellValue) => {
                        if (row.nextNode) {
                            return row.nextNode.name;
                        }
                        return '执行中';
                    },
                },
                {
                    prop: 'taskResult',
                    label: '处理结果',
                    formatter: (row, column, cellValue) => {
                        let data = null;
                        if (row && row.currentRecord) {
                            data = row.currentRecord.data.receipt.taskResult;
                        }
                        return data ? data : '';
                    },
                },
                {
                    prop: 'startTime',
                    label: '任务下发时间',
                    formatter: (row, column, cellValue) => {
                        if (row) {
                            return dayjs.utc(row.startTime).add(8, 'hours').format('YYYY-MM-DD HH:mm');
                        }
                    },
                },
                {
                    prop: 'endTime',
                    label: '任务处理期限',
                    formatter: (row, column, cellValue) => {
                        let data = null;
                        if (row && row.currentRecord) {
                            data = row.currentRecord.data.jobOrder.endTime;
                        }
                        return data ? data : '';
                    },
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'state',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择业务状态',
                        defaultValue: 'all',
                        options: [
                            {code: 'all', value: '全部'},
                            {code: 'processed', value: '已处理'},
                            {code: 'pending', value: '待处理'},
                            {code: 'selfStart', value: '我发起的'}
                        ],
                        valueKey: 'code',
                        labelKey: 'value'
                    },
                    {
                        prop: 'jobUnit',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择下发处理单位',
                        options: [
                            {code: '昆自运维服务分公司', value: '昆自运维服务分公司'},
                            {code: '光恒', value: '光恒'}
                        ],
                        valueKey: 'code',
                        labelKey: 'value',
                    },
                    {
                        prop: 'taskResult',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择处理结果',
                        options: this.taskResultList,
                        valueKey: 'name',
                        labelKey: 'name',
                    },
                ],
            };
        },
    },
    methods: {
        getButton(row) {
            let userCode = this.$store.state.userInfo.roleCodeList;
            let res = false;
            if (row.nextNode && userCode.indexOf('GZRWTZDXFR') > -1 && row.nextNode.code === 'elec_task_issue_start') {
                res = true;
            }
            if (row.nextNode && userCode.indexOf('GZRWTZDHZR') > -1 && row.nextNode.code === 'elec_task_issue_receipt') {
                res = true;
            }
            if (row.nextNode && userCode.indexOf('GZRWTZDSHR') > -1 && row.nextNode.code === 'elec_task_issue_audit') {
                res = true;
            }
            return res;
        },
        deleteInfo(row) {
            let ids = [];
            ids.push(row.id);
            this.$confirm(this.$t('确定要删除这条数据？'), this.$t('提示'), {
                confirmButtonText: this.$t('确认'),
                cancelButtonText: this.$t('取消'),
                type: 'warning'
            }).then(() => {
                this.$client.workflowDelete(ids).then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.$refs.searchTable.searchHandler();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        paramsMethod(params) {
            params.type = params.state;
            params['extraProp.jobUnit'] = params.jobUnit;
            params['extraProp.taskResult'] = params.taskResult;
            delete params.state;
            delete params.jobUnit;
            delete params.taskResult;
        },
        //是否有权限新增
        isCanAdd() {
            let user = this.$store.state.userInfo;
            if (user && user.roleCodeList && user.roleCodeList.indexOf('GZRWTZDXFR') > -1) {
                this.canAdd = true;
            }
        },
        //处理
        conductInfo(item, type) {
            this.showVisible = false;
            this.formVisible = true;
            this.$nextTick(() => {
                this.$refs.issuedTaskForm.init(item);
            });
        },
        //查看
        lookInfo(item) {
            this.showVisible = false;
            this.detailsVisible = true;
            this.$nextTick(() => {
                this.$refs.issuedTaskDetails.init(item);
            });
        },
        //关闭页面
        closeForm(type) {
            this.showVisible = true;
            this.detailsVisible = false;
            this.formVisible = false;
        },
        // 新增
        addOrUpdateHandle(type) {
            this.showVisible = false;
            this.formVisible = true;
            this.$nextTick(() => {
                this.$client.workflowInstanceProcess({
                    definitionCode: 'elec_task_issue',
                    user: this.$store.state.userInfo.username,
                    roles: this.$store.state.userRole
                }).then(resp => {
                    this.$refs.issuedTaskForm.init({
                        id: resp.data.instance.id,
                        nextNode: resp.data.nextNode,
                        definitionCode: 'elec_task_issue',
                        type,
                    });
                });
            });
        },
        fetchData(params) {
            /*if (params.extraProp) {
                for (let item in this.extraProp) {
                    params[`extraProp.${item}`] = this.extraProp[item];
                }
            }*/
            /*params.extraProp.jobUnit = params.jobUnit;
            params.extraProp.taskResult = params.taskResult;
            params.state = params.state;
            params.Type = 'pending';*/
            return new Promise((resolve, reject) => {
                this.$client.workflowInstanceQuery(params).then(data => {
                    let ids = [];
                    let result = data.data.items.map(e => {
                        e.currentRecordId = e.records.sort((a, b) => b.startTime > a.startTime ? 1 : -1)[0].id;
                        e.records.forEach(r => {
                            ids.push(r.id);
                        });
                        return e;
                    });
                    this.$client.getQueryRecords({ids}).then(resp => {
                        result = result.map(e => {
                            resp.data.items.sort();
                            e.currentRecord = resp.data.items.filter(i => i.id === e.currentRecordId)[0];
                            e.records.map(i => {
                                const record = resp.data.items.filter(r => r.id === i.id)[0];
                                i.data = record ? record.data : null;
                                return i;
                            });
                            e.currentStatus = params.type;
                            return e;
                        });
                        let tableData = {...data.data};
                        tableData.items = result;
                        resolve({data: tableData});
                    });
                });
            });
        },
        init() {
            this.$client.listDictionaryChildrenArr(['task_result']).then((res) => {
                this.taskResultList = res.data.task_result;
            });
        },
    },
    created() {
        this.init();
        this.isCanAdd();
    },
};
</script>
