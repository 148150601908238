<template>
    <div>
        <div class="form-table">
            <div style="text-align: center">
                <span style="font-size: 26px">
                    {{ workFlowState }}
                </span>
            </div>
            <div style="margin-top: 60px">
                <el-form ref="dataForm" :model="dataForm" :rules="dataRule" label-width="120px" label-position="top">
                    <ex-card headerName="任务通知单" style="margin-top: 30px">
                        <div>
                            <el-row :gutter="24">
                                <el-col :span="6">
                                    <el-form-item label="任务编号" prop="jobOrder.taskCode">
                                        <el-input v-model="dataForm.jobOrder.taskCode" placeholder="请填写任务编号">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="通知单位" prop="jobOrder.jobUnit">
                                        <el-select
                                            :disabled="this.start"
                                            v-model="jobUnitId"
                                            @change="selectUnit"
                                            clearable
                                            placeholder="请选择通知单位"
                                        >
                                            <el-option
                                                v-for="item in unitList"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="被通知人" prop="jobOrder.jobStaff">
                                        <el-select
                                            :disabled="this.start"
                                            v-model="dataForm.jobOrder.jobStaff"
                                            placeholder="请选择被通知人"
                                        >
                                            <el-option
                                                v-for="item in maintenanceOwnerList"
                                                :key="item.userId"
                                                :label="item.realName"
                                                :value="item.realName"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item
                                        label="工作类型"
                                        prop="jobOrder.workType"
                                        placeholder="请选择工作类型"
                                    >
                                        <el-select :disabled="this.start" v-model="dataForm.jobOrder.workType">
                                            <el-option
                                                v-for="item in workTypeList"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                        <!--<el-input :disabled="this.start" v-model="dataForm.jobOrder.workType" placeholder="请输入监理姓名"></el-input>-->
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="监理" prop="">
                                        <el-input
                                            :disabled="this.start"
                                            v-model="dataForm.jobOrder.supervisor"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="完成期限" prop="jobOrder.endTime">
                                        <el-date-picker
                                            :disabled="this.start"
                                            placeholder="请选择完成期限"
                                            v-model="dataForm.jobOrder.endTime"
                                            style="width: 100%"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="电压等级" prop="jobOrder.voltageClasses">
                                        <el-select
                                            :disabled="this.start"
                                            v-model="dataForm.jobOrder.voltageClasses"
                                            placeholder="请选择电压等级"
                                        >
                                            <el-option
                                                v-for="item in voltageClassesList"
                                                :value="item.id"
                                                :label="item.name"
                                                :key="item.name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-form-item label="工作任务" prop="jobOrder.jobContent">
                                    <el-input
                                        :disabled="this.start"
                                        v-model="dataForm.jobOrder.jobContent"
                                        type="textarea"
                                        rows="3"
                                        placeholder="输入内容"
                                    ></el-input>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="备注">
                                    <el-input
                                        :disabled="this.start"
                                        v-model="dataForm.jobOrder.remark"
                                        type="textarea"
                                        rows="3"
                                        placeholder="输入内容"
                                    ></el-input>
                                </el-form-item>
                            </el-row>
                        </div>
                    </ex-card>
                    <ex-card
                        headerName="缺陷回执单"
                        style="margin-top: 30px"
                        v-if="workFlowCode != 'elec_task_issue_start'"
                    >
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="处理结果" prop="receipt.taskResult">
                                    <el-select
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.taskResult"
                                        placeholder="处理结果"
                                    >
                                        <el-option
                                            v-for="item in taskResultList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.name"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="处理措施分类" prop="receipt.measure">
                                    <el-select
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.measure"
                                        placeholder="请选择处理措施分类"
                                    >
                                        <el-option
                                            v-for="item in treatmentMeasureClassifyList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.name"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="消缺开始时间" prop="receipt.taskStartTime">
                                    <el-date-picker
                                        :disabled="this.receipt"
                                        placeholder="请选择消缺开始时间"
                                        v-model="dataForm.receipt.taskStartTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="消缺结束时间" prop="receipt.taskEndTime">
                                    <el-date-picker
                                        :disabled="this.receipt"
                                        placeholder="请选择消缺结束时间"
                                        v-model="dataForm.receipt.taskEndTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="处理内容" prop="receipt.taskContent">
                                <el-input
                                    :disabled="this.receipt"
                                    v-model="dataForm.receipt.taskContent"
                                    type="textarea"
                                    rows="3"
                                    placeholder="请填写处理内容"
                                ></el-input>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="备注">
                                <el-input
                                    :disabled="this.receipt"
                                    v-model="dataForm.receipt.remark"
                                    type="textarea"
                                    rows="3"
                                    placeholder="输入内容"
                                ></el-input>
                            </el-form-item>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.roadworkUnit"
                                        placeholder="请填写施工单位/部门"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门责任人">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.roadworkPrincipal"
                                        placeholder="请填写责任人，多个人之间以，隔开"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门验收时间">
                                    <el-date-picker
                                        :disabled="this.receipt"
                                        placeholder="选择验收时间"
                                        v-model="dataForm.receipt.roadworkReceptionTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="施工单位/部门意见">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.roadworkIdea"
                                        placeholder="请填写验收意见"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.supervisorUnit"
                                        placeholder="请填写施工单位/部门"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门责任人">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.supervisorPrincipal"
                                        placeholder="请填写责任人，多个人之间以，隔开"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门验收时间：">
                                    <el-date-picker
                                        :disabled="this.receipt"
                                        placeholder="选择验收时间"
                                        v-model="dataForm.receipt.supervisorReceptionTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="监理单位/部门意见">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.supervisorIdea"
                                        placeholder="请填写验收意见"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.taskUnit"
                                        placeholder="请填写施工单位/部门"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门责任人">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.taskPrincipal"
                                        placeholder="请填写责任人，多个人之间以，隔开"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门验收时间">
                                    <el-date-picker
                                        :disabled="this.receipt"
                                        placeholder="选择验收时间"
                                        v-model="dataForm.receipt.taskReceptionTime"
                                        style="width: 100%"
                                        type="datetime"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="运维单位/部门意见">
                                    <el-input
                                        :disabled="this.receipt"
                                        v-model="dataForm.receipt.taskIdea"
                                        placeholder="请填写验收意见"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="change-line">
                            <el-row>
                                <span>消缺后图片以及总结报告上传</span>
                                <ex-uploader
                                    :disabled="this.receipt"
                                    v-model="dataForm.receipt.imageList"
                                ></ex-uploader>
                            </el-row>
                        </div>
                    </ex-card>
                    <ex-card
                        headerName="审核"
                        style="margin-top: 30px"
                        v-if="workFlowCode != 'elec_task_issue_receipt' && workFlowCode != 'elec_task_issue_start'"
                    >
                        <div style="text-align: center"></div>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="验收单位审核意见" prop="audit.auditIdea">
                                    <el-input :disabled="this.audit" v-model="dataForm.audit.auditIdea"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="验收单位审核时间" prop="audit.auditTime">
                                    <el-date-picker
                                        :disabled="this.audit"
                                        v-model="dataForm.audit.auditTime"
                                        style="width: 100%"
                                        type="date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </ex-card>
                </el-form>
            </div>
            <el-dialog title="选择驳回步骤" :visible.sync="dialogVisible" width="30%">
                <el-table
                    :data="rejectNodesList"
                    border
                    highlight-current-row
                    @current-change="handleCurrentChange"
                    style="width: 100%"
                    height="250"
                >
                    <el-table-column align="center" fixed prop="name" :show-overflow-tooltip="true" label="步骤名称">
                    </el-table-column>
                </el-table>
                <div style="text-align: center; margin-top: 10px">
                    <el-button type="primary" @click="rejectSubmit">驳回</el-button>
                </div>
            </el-dialog>
            <record-history v-if="showRecordHistory" :instance="data" />
        </div>
        <div class="submit-btn-group button-fixed">
            <el-button v-if="!this.start" type="primary" @click="saveApply()">下发</el-button>
            <el-button v-if="!this.receipt" type="primary" @click="saveApply()">回执</el-button>
            <el-button v-if="!this.audit" @click="showRejectDialog" type="primary">驳回</el-button>
            <el-button v-if="!this.audit" type="primary" @click="saveApply()">审核通过</el-button>
            <el-button @click="closeForm">关闭</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            reviewForm: {
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            },
            currentRow: null,
            data: {},
            showRecordHistory: false,
            rejectNodesList: [],
            dialogVisible: false,
            visible: true,
            roleList: [],
            roleCodeList: [],
            unitList: [], //运维单位列表
            maintenanceOwnerList: [], //运维单位成员列表
            voltageClassesList: [], //电压等级列表
            taskResultList: [], //处理结果列表
            workTypeList: [], //工作类型列表
            treatmentMeasureClassifyList: [], //处理措施分类列表
            roleInfo: [], //用户信息
            userName: '', //当前登录用户账号
            nodeCode: '', // 下一步流程ID
            instanceId: '', //当前流程ID
            jumpNodeId: '', //上一步流程ID
            jobUnitId: '', //运维单位id
            definitionCode: '', //流程id
            workFlowState: null, //流程状态
            workFlowCode: '', //流程code
            start: false, //开始流程
            receipt: false, //回执流程
            audit: false, //审核流程
            dataForm: {
                jobOrder: {
                    //工作任务通知单
                    taskCode: '', // 任务编号
                    jobUnit: '', //通知单位单位
                    jobStaff: '', //被通知人
                    workType: '', //工作类型
                    supervisor: '', //监理
                    endTime: '', //完成期限
                    voltageClasses: '', //电压等级
                    jobContent: '', //工作内容
                    remark: '', //备注
                },
                receipt: {
                    //回执单
                    taskResult: '', //处理结果
                    measure: '', //处理措施分类
                    taskStartTime: '', //处理开始时间
                    taskEndTime: '', //处理结束时间
                    taskContent: '', //处理内容
                    roadworkUnit: '', //施工单位
                    roadworkPrincipal: '', //施工单位负责人
                    roadworkReceptionTime: '', //施工单位验收时间
                    roadworkIdea: '', //施工单位意见
                    supervisorUnit: '', //监理单位
                    supervisorPrincipal: '', //监理单位负责人
                    supervisorReceptionTime: '', //监理单位验收时间
                    supervisorIdea: '', //监理单位意见
                    taskUnit: '', //运维单位
                    taskPrincipal: '', //运维单位负责人
                    taskReceptionTime: '', //运维单位验收时间
                    taskIdea: '', //运维单位意见
                    remark: '', //备注
                    imageList: [], //消缺后图片
                    finalReport: [], //总结报告
                },
                audit: {
                    //审核
                    auditIdea: '', //审核意见
                    auditTime: '', //审核时间
                    auditSignature: '', //验收单位签字
                },
            },
            dataRule: {
                jobOrder: {
                    //工作任务通知单
                    taskCode: [{required: true, message: '任务编号必填,不能为空', trigger: 'blur'}], //通知单位单位
                    jobUnit: [{required: true, message: '通知单位必填,不能为空', trigger: 'blur'}], //通知单位单位
                    jobStaff: [{required: true, message: '被通知人必填,不能为空', trigger: 'blur'}], //被通知人
                    workType: [{required: true, message: '工作类型必填,不能为空', trigger: 'blur'}], //工作类型
                    endTime: [{required: true, message: '完成期限必填,不能为空', trigger: 'blur'}], //完成期限
                    voltageClasses: [{required: true, message: '电压等级必填,不能为空', trigger: 'blur'}], //电压等级
                    jobContent: [{required: true, message: '工作内容必填,不能为空', trigger: 'blur'}], //工作内容
                    remark: [{required: true, message: '备注必填,不能为空', trigger: 'blur'}], //备注
                },
                receipt: {
                    //回执单
                    taskResult: [{required: true, message: '处理结果必填,不能为空', trigger: 'blur'}], //处理结果
                    measure: [{required: true, message: '处理措施分类必填,不能为空', trigger: 'blur'}], //处理措施分类
                    taskStartTime: [{required: true, message: '处理开始时间必填,不能为空', trigger: 'blur'}], //处理开始时间
                    taskEndTime: [{required: true, message: '处理结束时间必填,不能为空', trigger: 'blur'}], //处理结束时间
                    taskContent: [{required: true, message: '处理内容必填,不能为空', trigger: 'blur'}], //处理内容
                    taskUnit: [{required: true, message: '运维单位必填,不能为空', trigger: 'blur'}], //运维单位
                    taskPrincipal: [{required: true, message: '运维单位负责人必填,不能为空', trigger: 'blur'}], //运维单位负责人
                    taskReceptionTime: [{required: true, message: '运维单位验收时间必填,不能为空', trigger: 'blur'}], //运维单位验收时间
                    taskIdea: [{required: true, message: '运维单位意见必填,不能为空', trigger: 'blur'}], //运维单位意见
                    imageList: [{required: true, message: '消缺后图片必填,不能为空', trigger: 'blur'}], //消缺后图片
                    finalReport: [{required: true, message: '总结报告必填,不能为空', trigger: 'blur'}], //总结报告
                },
                audit: {
                    //审核
                    auditIdea: [{required: true, message: '审核意见必填,不能为空', trigger: 'blur'}], //审核意见
                    auditTime: [{required: true, message: '审核时间必填,不能为空', trigger: 'blur'}], //审核时间
                },
            },
        };
    },
    methods: {
        showRejectDialog() {
            this.dialogVisible = true;
        },
        rejectSubmit() {
            const data = Object.assign({}, this.dataForm, this.reviewForm);
            if (this.currentRow == null) {
                this.$message({
                    message: '请选择驳回步骤',
                    type: 'error',
                });
                return;
            }
            this.$confirm(`确定驳回到${this.currentRow.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_task_issue',
                        instanceId: this.data.id,
                        nodeCode: this.data.nextNode.code,
                        operationType: 'reject',
                        rejectNodeCode: this.currentRow.code,
                        data,
                        user: this.$store.state.userInfo.username,
                        roles: this.$store.state.userRole,
                        nextRoles: this.getRejectNextRoles(this.currentRow.code),
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                        });
                        this.closeForm(2);
                    });
            });
        },
        getRejectNextRoles(code) {
            let roleIds = [];
            if (code === 'elec_task_issue_start') {
                this.roleCodeList.forEach((item) => {
                    if (item.roleCode === 'GZRWTZDXFR') {
                        roleIds.push(item.roleId);
                    }
                });
            }
            if (code === 'elec_task_issue_receipt') {
                this.roleCodeList.forEach((item) => {
                    if (item.roleCode === 'GZRWTZDHZR') {
                        roleIds.push(item.roleId);
                    }
                });
            }
            if (code === 'elec_task_issue_audit') {
                this.roleCodeList.forEach((item) => {
                    if (item.roleCode === 'GZRWTZDSHR') {
                        roleIds.push(item.roleId);
                    }
                });
            }
            if (roleIds.length < 1) {
                return;
            }
            return roleIds;
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },

        getDisabled(workFlowCode) {
            if (workFlowCode === 'elec_task_issue_start') {
                this.start = false; //开始流程
                this.receipt = true; //回执流程
                this.audit = true; //审核流程
            }
            if (workFlowCode === 'elec_task_issue_receipt') {
                this.start = true; //开始流程
                this.receipt = false; //回执流程
                this.audit = true; //审核流程
            }
            if (workFlowCode === 'elec_task_issue_audit') {
                this.start = true; //开始流程
                this.receipt = true; //回执流程
                this.audit = false; //审核流程
            }
            if (workFlowCode === null || workFlowCode === '') {
                this.start = true; //开始流程
                this.receipt = true; //回执流程
                this.audit = true; //审核流程
            }
        },
        //获取运维单位成员
        selectUnit(val) {
            this.dataForm.jobOrder.jobStaff = '';
            for (let item of this.unitList) {
                if (item.id === val) {
                    this.dataForm.maintenanceUnit = item.name;
                }
            }
            this.$client.getUserByOrgId(val).then((res) => {
                this.maintenanceOwnerList = res.data;
            });
        },
        getInitData() {
            //约定维保组织code以WB开头， 获取运维单位列表
            this.$client.getOrgUnitByOrgTypeAndCode({orgType: 'department', code: 'WB'}).then((res) => {
                this.unitList = res.data;
            });
            let unitType = 'GZRW';
            this.$client.queryOverhaulRoleList(unitType).then((res) => {
                this.roleCodeList = res.data;
            });
            //获取电压等级列表
            this.$client
                .listDictionaryChildrenArr([
                    'voltage_classes',
                    'task_result',
                    'treatment_measure_classify',
                    'work_type',
                ])
                .then((res) => {
                    this.voltageClassesList = res.data.voltage_classes;
                    this.taskResultList = res.data.task_result;
                    this.treatmentMeasureClassifyList = res.data.treatment_measure_classify;
                    this.workTypeList = res.data.work_type;
                });
        },
        closeForm() {
            this.$emit('closeForm');
        },
        async init(item) {
            if (item) {
                this.rejectNodesList = item.rejectNodes;
                this.definitionCode = item.definitionCode;
                this.data = item;
                this.instanceId = item.id;
                if (item.nextNode) {
                    this.nodeCode = item.nextNode.code;
                    this.workFlowState = item.nextNode ? item.nextNode.name : '任务下发通知单';
                    this.workFlowCode = item.nextNode ? item.nextNode.code : '';
                }
                if (item.currentRecordId) {
                    let ids = [];
                    ids.push(item.currentRecordId);
                    this.$client.getQueryRecords({ids}).then((resp) => {
                        this.dataForm = resp.data.items[0].data;
                        let data = this.unitList.filter((e) => e.name === this.dataForm.jobOrder.jobUnit)[0];
                        if (data) {
                            this.jobUnitId = data.id;
                        }
                    });
                }
            }
            this.getRoleList();
            await this.getInitData();
            this.getUser();
            this.getDisabled(this.workFlowCode);
            this.visible = true;
        },
        //发起流程
        saveApply() {
            let nextRoles = [];
            let userRoleIds = this.$store.state.userInfo.roleCodeList;
            if (this.roleList) {
                //设置下环节
                userRoleIds.forEach((temp) => {
                    this.roleCodeList.forEach((item) => {
                        if (temp === 'GZRWTZDXFR' && item.roleCode === 'GZRWTZDHZR') {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'GZRWTZDHZR' && item.roleCode === 'GZRWTZDSHR') {
                            nextRoles.push(item.roleId);
                        }
                    });
                });
            }
            //封装表单数据
            let s = this.unitList.filter((e) => e.id === this.jobUnitId)[0];
            const data = Object.assign({}, this.dataForm, this.reviewForm);
            if (this.dataForm.jobOrder.jobUnit === '' || this.dataForm.jobOrder.jobUnit == null) {
                data.jobOrder.jobUnit = s?.name;
            }
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.$confirm('确认通过？', '提示', {
                        confirmButtonClass: 'el-button el-button--mini el-button--primary',
                        type: 'warning',
                    }).then(() => {
                        this.$client
                            .workflowInstanceProcess({
                                definitionCode: 'elec_task_issue',
                                instanceId: this.instanceId,
                                nodeCode: this.nodeCode,
                                operationType: 'saveAndNext',
                                data,
                                user: this.$store.state.userInfo.username,
                                roles: this.$store.state.userRole,
                                nextRoles,
                                extraProp: {
                                    createUser: this.$store.state.userInfo.username,
                                    jobUnit: data.jobOrder.jobUnit,
                                    taskResult: data.receipt.taskResult,
                                },
                            })
                            .then((resp) => {
                                if (resp.code === 0) {
                                    this.closeForm(1);
                                }
                            });
                    });
                }
            });
        },
        //获取昆自运维中心人员列表
        getRoleList() {
            this.$client
                .getUserListByRoleId({
                    current: 1,
                    size: 1000,
                    roleId: 15,
                })
                .then((data) => {
                    this.roleList = data.data.records;
                });
        },
        //获取用户信息
        async getUser() {
            const data = await this.$client.getUserInfo();
            let id = data.user.userId;
            let roleData = await this.$client.getUserById(id);
            this.userName = roleData.user.username;
            let res = roleData.user.sysRole;
            for (let i = 0; i < res.length; i++) {
                this.roleInfo.push(res[i].roleCode);
            }
        },
    },
};
</script>
<style lang="less" scoped>
.form-table {
    height: calc(100vh - 170px);
    margin-bottom: 10px;
    overflow-y: scroll;
}
.button-fixed {
    text-align: center;
    margin: 0 !important;
}
</style>
